import TypesetText from '~/editor/typeset-text/typeset-text'
import { appendTypesetText } from '~/services/current-document/typeset-texts'
import { LoadTypesetTextsProps } from '~/services/current-document/loading/typeset-text.interfaces'
import { setLoadingState } from '~/services/loading/loading'
import { translations } from '~/translations'
import { Point } from 'fabric'
import { referenceWidth } from '~/services/static/document'
import { TypesetTextBoundingBox } from '~/editor/typeset-text/typeset-text.interfaces'
import Translation from '~/editor/translation/translation'

const loadTypesetTexts = async ({ currentDocument, chapterData, typesetTextsData, setIsLoading }: LoadTypesetTextsProps) => {
  setLoadingState({
    loading: true,
    message: translations().loading.default
  })
  currentDocument.loadPages(chapterData.pages.clean).then(() => {
    // Reduce to unique uuids
    const uniqueIds = typesetTextsData.reduce<string[]>((result, entry) => {
      if (!result.includes(entry.entry_id)) {
        result = result.concat(entry.entry_id)
      }
      return result
    }, [])

    uniqueIds.forEach(id => {
      // Group versions using their parent translation uuid
      const versions = typesetTextsData
        .filter(entry => entry.entry_id === id)
        .sort((a, b) => (new Date(a.created_at).getTime() - new Date(b.created_at).getTime()))
      const lastVersion = versions[versions.length - 1]
      
      // Get the page where the typesetText is
      const pageIndex = chapterData.pages.clean.findIndex(page => page.id === lastVersion.page_id)
      const page = currentDocument.pages[pageIndex]
      const styles = lastVersion.style ? JSON.parse(lastVersion.style) : undefined
      const charStyles = lastVersion.char_styles && lastVersion.char_styles[0] !== '{' ? JSON.parse(lastVersion.char_styles) : undefined
      if(page){
        const typesetText = new TypesetText({
          id,
          text: lastVersion.text ?? 'Empty text',
          styles,
          charStyles,
          boundingBox: JSON.parse(lastVersion.bounding_box),
          page,
          date: new Date(lastVersion.created_at).toUTCString(),
          settings: currentDocument.settings,
          translationId: lastVersion.translation_id
        })
        appendTypesetText(typesetText, { select: false })
      }
    })
  }).finally(() => {
    setLoadingState({
      loading: false
    })
    setIsLoading(false)
  })
}


const getBoundingBox = (entry: Translation) => {
  if(!entry.startPoint || !entry.endPoint){
    entry.startPoint = new Point({ x: 0.1, y: 0.1 })
    entry.endPoint = new Point({ x: 0.1, y: 0.1 })
  }

  const relativeWidth = Math.abs(entry.startPoint.x - entry.endPoint.x)
  const absoluteWidth = relativeWidth * referenceWidth

  const translationCenterX = entry.startPoint.x + (relativeWidth / 2)
  const translationCenterY = entry.startPoint.y
  const startPoint = new Point({
    x: translationCenterX,
    y: translationCenterY,
  })

  const boundingBox: TypesetTextBoundingBox = {
    startPoint,
    relativeWidth,
    absoluteWidth
  }

  return boundingBox
}

export {
  loadTypesetTexts,
  getBoundingBox
}